import { Card } from '@troon/ui';
import { Show, createMemo } from 'solid-js';
import { useUser } from '../providers/user';
import { TroonCardSubscriptionProductType } from '../graphql';
import { RichText } from './rich-text';
import type { ComponentProps } from 'solid-js';
import type { Heading } from '@troon/ui';
import type { TypeAccessBenefitSkeleton } from '@troon/contentful';
import type { Entry } from 'contentful';

type Props = Entry<TypeAccessBenefitSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>['fields'] & {
	level?: ComponentProps<typeof Heading>['as'];
	size?: ComponentProps<typeof Heading>['size'];
};

export function BenefitCard(props: Props) {
	const user = useUser();

	const hasBenefit = createMemo(
		() =>
			user()?.me.troonAccessProductType &&
			(user()!.me.troonAccessProductType === TroonCardSubscriptionProductType.TroonAccessPlus ||
				accessProductTypeToContentfulLevel[user()!.me.troonAccessProductType!] === props.accessLevel),
	);

	return (
		<Show when={!user()?.me || !user()?.me.troonAccessProductType || hasBenefit()}>
			<>
				{/* @ts-ignore */}
				<Card
					title={props.title}
					img={props.logoImage!.fields.file!.url}
					imgAlt={props.logoImage!.fields.description}
					banner={props.bannerImage?.fields.file?.url}
					bannerAlt={props.bannerImage?.fields.description}
					level={props.level}
					size={props.size}
					url={hasBenefit() ? props.url : undefined}
					linkText={hasBenefit() ? props.linkText : undefined}
				>
					<RichText document={hasBenefit() ? (props.contentWithCode ?? props.content) : props.content} />
				</Card>
			</>
		</Show>
	);
}

const accessProductTypeToContentfulLevel: Record<
	TroonCardSubscriptionProductType,
	TypeAccessBenefitSkeleton['fields']['accessLevel']['values']
> = {
	[TroonCardSubscriptionProductType.TroonAccess]: 'Access',
	[TroonCardSubscriptionProductType.TroonAccessPlus]: 'Access+',
};
